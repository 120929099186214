@import "./styles/rules";

body,
#root {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

body {
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    padding: 5px;
    border-radius: 7px;
    background-color: $scroll-background;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 7px;
    --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    background: $scroll-color;
  }
}

.pageContent {
  flex: 1 0 auto;
  position: relative;
  height: 100%;
  background: $black-russian;
}

.loader {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.myFooter {
  background: linear-gradient(90deg, #f42525, #000d44);
  color: white;
  padding: 1rem 2rem;
  text-align: center;
  z-index: 2;
  font-size: 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  > span {
    display: flex;
    align-items: center;

    > a {
      margin-left: 0.25rem;
    }
  }
}

@media only screen and (min-width: 992px) {
  .pageContent {
    height: auto;
  }
}

@media only screen and (min-width: 1400px) {
  .pageContent {
    height: 75%;
  }
}
