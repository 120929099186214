.app-loader{
  padding: 30px;
  text-align: center;
}

.admin-list-loader {
  position: absolute;
  width: 100%;
  top: 20px;
}

.payment-button-loader{
  padding: 0px !important;
  text-align: center;
}

.news-loader {
  position: fixed;
  z-index: 999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
}

.prediction-loader {
  position: absolute;
  width: 100%;
  top: 0;
  padding: 0;
}