$white: #ffffff;
$black: #000000;
$black-russian: #090d28;
$black-russian-opacity: rgba(9, 13, 40, 0.65);
$grayish-navy: #181c3a;
$grayish-navy-opacity: rgba(24, 28, 58, 0.65);
$waikawa-grey: #646b9b;
$blue-violet: #8d29ff;
$pastel-green: #00c84a;
$fun-green: #048533;
$zuccini: #075a26;
$sunsent-orange: #ff5136;
$red-orange: #ff2626;
$yellow-turbo: #ffd21c;
$turquoise: #2ee9d0;
$midnight-express: #131734;
$Bali-Hai: #8f9bb3;

$bg-primary: $black-russian;
$bg-secondary: $grayish-navy;
$bg-secondary-opacity: $grayish-navy-opacity;
$bg-secondary-darker: $midnight-express;
$bg-colored: $pastel-green;
$bg-violet: $blue-violet;

$color-primary: $white;
$color-secondary: $pastel-green;
$color-neutral: $waikawa-grey;
$color-violet: $blue-violet;
$color-text: $Bali-Hai;
// $color-text: $white;

$link: $waikawa-grey;
$link-hover: $pastel-green;
$link-active: $pastel-green;

$btn-primary: $pastel-green;
$btn-primary-shadow: $fun-green;
$btn-primary-active: $zuccini;
$btn-primary-color: $white;
$btn-secondary: $waikawa-grey;
$btn-secondary-color: $white;
$btn-dark: $bg-secondary;
$btn-black: $bg-primary;
$btn-bright: $blue-violet;

$input-bg: $black-russian;
$input-color: $white;
$input-placeholder: $waikawa-grey;
$input-active-border: $blue-violet;

$font-primary: "Arial";
$font-primary-bold: "Arial";
$font-secondary: "Arial-Black";

// My Colors
$white: #ffffff;
$haiti: #090d28;
$haiti08: rgba(9, 13, 40, 0.8);
$transparent: transparent;
$cinnabar: #e64d23;
$cinnabar06: rgba(230, 77, 35, 0.6);
$linearGradient: linear-gradient(90deg, #f42525, #000d44);
$lightGray: #dfdfdf;
$green: green;
$orange: orange;

// $linearGradient: linear-gradient(90deg, #e64d23, #090d28);

$card-background: linear-gradient(rgba(9, 13, 40, 0.2), rgb(9, 13, 40));
$card-background-hover: linear-gradient(
  rgba(255, 255, 255, 0),
  rgba(255, 255, 255, 1)
);

// Text
$text-primary: $white;
$text-secondary: $haiti;

// Buttons
$btn-primary-text: $white;
$btn-primary-text-hover: $haiti;
$btn-primary-background: $haiti;
$btn-primary-background-hover: $white;

$btn-secondary-border: $white;
$btn-secondary-text: $white;
$btn-secondary-text-hover: $haiti;
$btn-secondary-background: $transparent;
$btn-secondary-background-hover: $white;

$btn-registrationHome-background: $cinnabar06;

// Other
$timer-background: $haiti08;

// Scroll
$scroll-background: rgba(255, 255, 255, 0.1);
$scroll-color: $lightGray;

// Map Details
$malaMapa-background: rgba(95, 255, 47, 0.1);
$malaMapa-color: $green;
$malaMapa-borders: $green;

$srednjaMapa-background: rgba(255, 189, 47, 0.1);
$srednjaMapa-color: $orange;
$srednjaMapa-borders: $orange;

$velikaMapa-background: rgba(255, 47, 47, 0.1);
$velikaMapa-color: $cinnabar;
$velikaMapa-borders: $cinnabar;

//Breakpoints
$breakpoint-wide-screens: 1200px;
$breakpoint-desktops: 992px;
$breakpoint-tablets: 767px;
$breakpoint-phones: 480px;
$breakpoint-web-mobile: 992px;
